import { ReactElement } from 'react';
import styles from './BusySpinner.module.scss';

interface BusySpinnerProps {
	size: string;
}

export default function BusySpinner({ size }: BusySpinnerProps): ReactElement {
	return (
		<img
			src="/img/logo.svg"
			alt=""
			className={styles.spinner}
			style={{ height: size, width: size }}
		/>
	);
}
