import BusySpinner from '../busySpinner/BusySpinner';
import styles from './RouteLoader.module.scss';

export default function RouteLoader() {
	return (
		<div className={styles.container}>
			<BusySpinner size={'10em'} />
			<p className={styles.text}>Perla is loading...</p>
		</div>
	);
}
