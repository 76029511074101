import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Category } from '../../common/types';

export interface ServiceState {
	categoryName: string;
	serviceName: string;
	history: Category[];
	selected: Category | null;
}

const initialState: ServiceState = {
	categoryName: '',
	serviceName: '',
	history: [],
	selected: null,
};

export const serviceSlice = createSlice({
	name: 'service',
	initialState,
	reducers: {
		selectCategory: (state, action: PayloadAction<Category>) => {
			state.selected = action.payload;
			state.categoryName = action.payload.name;
		},
		selectService: (state, action: PayloadAction<Category>) => {
			if (state.selected) {
				state.history.push(state.selected);
			}
			state.selected = action.payload;
			state.serviceName = action.payload.name;
		},
		goBackInHistory: state => {
			const previous = state.history.pop();
			state.selected = previous ?? null;
			state.serviceName = previous?.name ?? '';
		},
		reset: state => {
			state.history = [];
			state.selected = null;
		},
	},
});

export const {
	selectCategory,
	selectService,
	goBackInHistory,
	reset,
} = serviceSlice.actions;

export const selectCategoryName = (state: RootState) =>
	state.services.categoryName;
export const selectServiceName = (state: RootState) =>
	state.services.serviceName;
export const selectSelectedCategory = (state: RootState) =>
	state.services.selected;

export default serviceSlice.reducer;
