import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setConfigItem } from '../../config';

export interface AffiliateState {
	affiliateId: string;
}

const initialState: AffiliateState = {
	affiliateId: '',
};

export const affiliateSlice = createSlice({
	name: 'affiliate',
	initialState,
	reducers: {
		setAffiliateId: (state, action: PayloadAction<string>) => {
			setConfigItem('affiliateId', action.payload);
			state.affiliateId = action.payload;
		},
	},
});

export const { setAffiliateId } = affiliateSlice.actions;

export default affiliateSlice.reducer;
