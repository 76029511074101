import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import styles from './HeaderMenu.module.scss';
import menuIcon from '../assets/images/menu.svg';
import {
	academyLink,
	advisorLink,
	ascLink,
	blogLink,
	dentalLink,
	medicalLink,
} from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHouseChimneyMedical,
	faTooth,
} from '@fortawesome/free-solid-svg-icons';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faBlog } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default function HeaderMenu({ logedInUser }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const showMedical = logedInUser.company?.verified_by_perla
		? logedInUser.company?.type === 'medical'
		: true;
	const showDental = logedInUser.company?.verified_by_perla
		? logedInUser.company?.type === 'dental'
		: true;
	const showASC = logedInUser.company?.verified_by_perla
		? logedInUser.company?.type === 'asc'
		: true;
	return (
		<div>
			<Button
				aria-describedby={id}
				variant="text"
				color="primary"
				onClick={handleClick}
				className={styles.menuBtn}
			>
				<img alt="menu" src={menuIcon} />
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={styles.content}>
					{showDental && (
						<a href={dentalLink}>
							<FontAwesomeIcon icon={faTooth} color="black" />
							{/* <img src={faTooth} /> */}
							<span>Dental Suite</span>
						</a>
					)}
					{showMedical && (
						<a href={medicalLink}>
							<FontAwesomeIcon
								icon={faStethoscope}
								color="black"
							/>
							{/* <img src={medicalIcon} /> */}
							<span>Medical Suite</span>
						</a>
					)}
					{showASC && (
						<a href={ascLink}>
							<FontAwesomeIcon
								icon={faStethoscope}
								color="black"
							/>
							{/* <img src={medicalIcon} /> */}
							<span>ASC Suite</span>
						</a>
					)}
					<a href="#">
						<FontAwesomeIcon
							icon={faHouseChimneyMedical}
							color="black"
						/>
						{/* <img src={medicalIcon} /> */}
						<span>Home Health</span>
					</a>

					<a href={advisorLink}>
						<FontAwesomeIcon icon={faUsers} color="black" />
						{/* <img src={faTooth} /> */}
						<span>Advisor Leeds </span>
					</a>
					<a href="#">
						<FontAwesomeIcon icon={faAward} color="black" />
						{/* <img src={faTooth} /> */}
						<span>Verification </span>
					</a>
					<a href={academyLink}>
						<FontAwesomeIcon icon={faGraduationCap} color="black" />
						{/* <img src={faTooth} /> */}
						<span>Academy </span>
					</a>

					<a href={blogLink}>
						<FontAwesomeIcon icon={faBlog} color="black" />
						{/* <img src={faTooth} /> */}
						<span>Blog </span>
					</a>
					<a href="#">
						<FontAwesomeIcon icon={faShoppingCart} color="black" />
						{/* <img src={faTooth} /> */}
						<span>GPO </span>
					</a>
				</div>
			</Popover>
		</div>
	);
}
