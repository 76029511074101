import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface LogedInUserState {
	firstName: string;
	lastName: string;
	email: string;
	avatar: string;
	token: string;
	isAdvisor: boolean;
	status?: boolean;
	enableReview?: boolean;
	company?: {
		_id: string;
		company_legal_name: string;
		verified_by_perla: string;
		credit_card: string;
		selected_plan: string;
		plan_duration: string;
		subscriptionEndDate: string;
		type?: string;
	} | null;
	role: string;
	id: string;
}

const initialState: LogedInUserState = {
	firstName: '',
	lastName: '',
	email: '',
	avatar: '',
	token: '',
	isAdvisor: false,
	status: false,
	enableReview: false,
	company: null,
	role: '',
	id: '',
};

export const logedInUserSlice = createSlice({
	name: 'logedInUser',
	initialState,
	reducers: {
		setLogedInUserInfo: (
			state,
			action: PayloadAction<LogedInUserState>,
		) => {
			state.email = action.payload.email;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.avatar = action.payload.avatar;
			state.token = action.payload.token;
			state.isAdvisor = action.payload.isAdvisor;
			state.status = action.payload.status;
			state.role = action.payload.role;
			state.id = action.payload.id;
		},
		setProfile: (state, action: PayloadAction<LogedInUserState>) => {
			state.email = action.payload.email;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.avatar = action.payload.avatar;
			state.token =
				state.token.length > 0 ? state.token : action.payload.token;
			state.isAdvisor = action.payload.isAdvisor;
			state.status = action.payload.status;
			state.company = action.payload.company;
			state.enableReview = action.payload.enableReview;
			state.role = action.payload.role;
			state.id = action.payload.id;
		},
		resetLogedInUserState: state => initialState,
	},
});

export const {
	setLogedInUserInfo,
	resetLogedInUserState,
	setProfile,
} = logedInUserSlice.actions;

export const selectLogedInUserInfo = (state: RootState) => state.logedInUser;

export default logedInUserSlice.reducer;
