import React, {
	lazy,
	ReactElement,
	Suspense,
	useEffect,
	useState,
} from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useHistory,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import RouteLoader from './components/routeLoader/RouteLoader';
import Header from './common/Header/Header';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
	resetLogedInUserState,
	selectLogedInUserInfo,
	setProfile,
} from './features/logedInUser/logedInUserSlice';
import Footer from './common/Footer/Footer';
import { GetMyProfile, LogoutService } from './services';
import { resetAllSearch } from './features/search/searchSlice';
import Cookies from 'js-cookie';

const routes = [
	{
		path: '/',
		component: lazy(() => import('./routes/terms/terms')),
		exact: true,
	},
	{
		path: '/linkedin',
		component: lazy(() => import('./routes/linkedin/Linkedin')),
	},
	{
		path: '/signin/client',
		component: lazy(() => import('./routes/signin/SigninAsClient')),
		exact: true,
	},
	{
		path: '/signin/client/signin-with-email',
		component: lazy(
			() => import('./routes/signin/signinWithEmail/signinWithEmail'),
		),
	},
	{
		path: '/signin/client/signup-with-email',
		component: lazy(
			() => import('./routes/signin/signupWithEmail/signupWithEmail'),
		),
	},
	{
		path: '/signin/client/verify-code/:userId/:type?',
		component: lazy(() => import('./routes/signin/VerifyCode/verifyCode')),
	},
	{
		path: '/signin/client/forgert-password',
		component: lazy(
			() => import('./routes/signin/ForgetPassword/ForgetPassword'),
		),
	},
	{
		path: '/signin/client/change-password/:operationId',
		component: lazy(
			() => import('./routes/signin/ChangePassword/ChangePassword'),
		),
	},
	{
		path: '/signin',
		component: lazy(() => import('./routes/signin/Signin')),
	},
	{
		path: '/cliam-user-dashboard',
		component: lazy(() => import('./routes/signin/ClaimYourUserDashboard')),
	},
	{
		path: '/verify-code',
		component: lazy(() => import('./routes/signin/VerifyCode')),
	},
	{
		path: '/claimed-suucessfully',
		component: lazy(() => import('./routes/signin/SuccessfullyClaimed')),
	},
	{
		path: '/',
		component: lazy(() => import('./routes/terms/terms')),
		exact: true,
	},
];

function App(): ReactElement {
	const logedInUser = useAppSelector(selectLogedInUserInfo);
	const dispatch = useAppDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const token = Cookies.get('token') || '';

	useEffect(() => {
		if (
			(logedInUser.token && logedInUser.token.length > 0) ||
			token.length > 0
		) {
			GetMyProfile(
				logedInUser.token && logedInUser.token.length > 0
					? logedInUser.token
					: token,
			).then(({ data, status }) => {
				if (status === 200) {
					if (data.user) {
						const userInfo = {
							avatar: data.user.photo ? data.user.photo : '',
							email: data.user.email,
							firstName: data.user.first_name,
							lastName: data.user.last_name,
							status: data.user.status,
							token: token,
							isAdvisor: data.user.isAdvisor,
							company: data.user.company,
							enableReview: data.user.enableReview,
							role: data.user.role,
							id: data.user._id,
						};
						dispatch(setProfile(userInfo));
					} else {
						localStorage.clear();
						Cookies.remove('token');
					}
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
			Cookies.remove('token');
		}
	}, [logedInUser.token]);
	const logoutProccess = cb => {
		setLoading(true);
		// e.preventDefault();
		LogoutService(logedInUser.token).then(({ data, status }) => {
			localStorage.clear();
			Cookies.remove('token');
			dispatch(resetLogedInUserState());
			dispatch(resetAllSearch());
			// history.push('/');
			cb();
			setLoading(false);
		});
	};
	return (
		<div className="app">
			<Router>
				<Header
					logoutProccess={logoutProccess}
					logedInUser={logedInUser}
				/>
				{loading ? (
					<RouteLoader />
				) : (
					<Suspense fallback={<RouteLoader />}>
						<Switch>
							{routes.map((route, index) => (
								<Route
									key={index}
									exact={route.exact ?? false}
									path={route.path}
									component={route.component}
								/>
							))}
						</Switch>
					</Suspense>
				)}
				<Footer />
			</Router>
			<ToastContainer />
		</div>
	);
}

export default App;
