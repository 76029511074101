const linkedinClientId = '78xufgs8fgtygx';
const perlaLink = 'https://goperla.com/';
const medicalLink = 'https://medical.goperla.com/';
const termsLink = 'https://terms.goperla.com/';
const ascLink = 'https://asc.goperla.com/';
// const medicalLink = 'http://localhost:3000/landing';
const dentalLink = 'https://dental.goperla.com/';
const advisorLink = 'https://apps.goperla.com/advisor';
const academyLink = 'https://apps.goperla.com/perla-academy';
const blogLink = 'https://apps.goperla.com/blog';
export {
	linkedinClientId,
	medicalLink,
	dentalLink,
	advisorLink,
	academyLink,
	blogLink,
	perlaLink,
	ascLink,
	termsLink,
};
