import React, { useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import styles from './HeaderProfile.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../assets/images/Search.svg';
import ClientProfileIcon from '../assets/images/client-profile.svg';
import ReviewIcon from '../assets/images/review.svg';
import LogoutIcon from '../assets/images/Logout.svg';
import MedicalBusinessSuiteIcon from '../assets/images/medical-business-suite.svg';
import { ascLink, dentalLink, medicalLink, perlaLink } from '../constants';

export default function HeaderProfile({
	showMedicalMenu,
	logedInUser,
	logoutProccess,
}) {
	const history = useHistory();
	const pathName = useLocation().pathname;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	useEffect(() => {
		handleClose();
	}, [pathName]);
	const companyType = logedInUser?.company?.type;
	console.log(logedInUser);

	return (
		<div>
			<Button
				aria-describedby={id}
				variant="text"
				color="primary"
				onClick={handleClick}
				className={styles.profileBtn}
			>
				<img
					src={logedInUser.avatar || '/missing_profile_picture.png'}
					// src={'/missing_profile_picture.png'}
					alt="Clyde Lucas"
					className={styles.avatar}
				/>
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={styles.content}>
					<a
						href={perlaLink}
						onClick={() => {
							if (pathName === '/' || pathName === '') {
								handleClose();
								history.go(0);
							}
						}}
					>
						<img alt="Search" src={SearchIcon} />
						<span>Search for Advisors</span>
					</a>
					<a
						href={`${perlaLink}client/account`}
						onClick={() => {
							if (
								pathName === '/client/account' ||
								pathName === '/client/account/'
							) {
								handleClose();
								history.go(0);
							}
						}}
					>
						<img alt="profile" src={ClientProfileIcon} />
						<span>My Search Dashboard</span>
					</a>
					{showMedicalMenu && (
						<>
							<a
								href={`${
									companyType === 'medical'
										? medicalLink
										: companyType === 'dental'
										? dentalLink
										: ascLink
								}business-suite`}
							>
								<img
									alt={companyType}
									src={MedicalBusinessSuiteIcon}
								/>
								<span>
									{companyType === 'medical'
										? 'Medical'
										: companyType === 'dental'
										? 'Dental'
										: 'ASC'}{' '}
									Suite Dashboard
								</span>
							</a>
							{['Admin', 'Super Admin'].includes(
								logedInUser.role,
							) && (
								<a
									href={`${
										companyType === 'medical'
											? medicalLink
											: companyType === 'dental'
											? dentalLink
											: ascLink
									}admin-console`}
								>
									<img
										alt="admin console"
										src={MedicalBusinessSuiteIcon}
									/>
									<span>Admin Console</span>
								</a>
							)}
						</>
					)}
					{logedInUser.enableReview && (
						<a
							href={`${perlaLink}client/reviews`}
							onClick={() => {
								if (
									pathName === '/client/reviews' ||
									pathName === '/client/reviews/'
								) {
									handleClose();
									history.go(0);
								}
							}}
						>
							<img alt="my reviews" src={ReviewIcon} />
							<span>My Reviews</span>
						</a>
					)}
					<a onClick={logoutProccess}>
						<img alt="logout" src={LogoutIcon} />
						<span>Sign Out</span>
					</a>
				</div>
			</Popover>
		</div>
	);
}
